.notFound {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.notFound__error {
    margin: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 140px;
    line-height: 169px;
    text-align: center;
    color: #000000;
}

.notFound__text {
    margin: 5px 0 0 0;
    text-align: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
}

.notFound__button {
    margin: 184px 0 60px 0;
    border: none;
    background-color: inherit;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #777777;
    cursor: pointer;
    opacity: 1;
    transition: opacity .3 ease;
}

.notFound__button:hover {
    opacity: .6;
}

@media screen and (max-width: 870px) {
    .notFound {
        padding-top: 200px;
    }
}

@media screen and (max-width: 540px) {
    .notFound {
        padding-top: 157px;
    }

    .notFound__error {
        font-size: 80px;
        line-height: 97px;
    }

    .notFound__text {
        margin-top: 10px;
        font-size: 12px;
        line-height: 15px;
    }

    .notFound__button {
        margin: 284px 0 30px 0;
        font-size: 12px;
        line-height: 15px;
    }
}
