.note {
    max-width: 100%;
    padding: 30px 70px;
    margin-bottom: 150px;
}

.note__container {
    max-width: 100%;
    display: flex;
    justify-content: center;
}

.note__info {
    max-width: 600px;
    display: flex;
    flex-direction: column;
    margin-right: 150px;
    justify-content: space-between;
    align-items: center;
}

.note__title {
    margin: 0;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 1.2;
}

.note__text {
    margin: 18px 0 0 0;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
}

.note__photo {
    width: 500px;
    height: 570px;
    border-radius: 2px;
    background-image: url("../../images/noteBack.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.note__quote {
    margin: 0;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.5;
    position: relative;
}

.note__qb {
    margin: 60px 0 0 0;
    padding: 20px;
    background: linear-gradient(to right, #D3D8DB 0, #D3D8DB 50%, transparent 50%, transparent 100%), linear-gradient(to right, transparent 0, transparent 50%, #D3D8DB 50%, #D3D8DB 100%), linear-gradient(to bottom, #D3D8DB 0, #D3D8DB 100%), linear-gradient(to bottom, #D3D8DB 0, #D3D8DB 100%);
    background-size: 50% 2px, 50% 2px, 2px 50%, 2px 50%;
    background-position: 0 0, 100% 100%, 0 0, 100% 100%;
    background-repeat: no-repeat;
}

.note__link {
    color: #000;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    opacity: 1;
    transition: opacity 0.3 ease;
}

.note__link:hover {
    opacity: 0.6;
    cursor: pointer;
}

.note__btn {
    width: 250px;
    height: 50px;
    border: 1px solid #000;
    border-radius: 4px;
    padding: 0;
    margin: 0;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    background-color: #fff;
    color: #000;
    transition: background-color 0.3 ease;
}

.note__btn:hover {
    background-color: #D3D8DB;
    cursor: pointer;
    color: #fff;
    border: none;
}

@media screen and (max-width: 1393px) {
    .note__info {
        max-width: 500px;
        margin-right: 100px;
    }

    .note__title {
        margin: 0;
        font-size: 40px;
    }
    .note__text {
        margin: 10px 0 0 0;
    }

    .note__qb {
        width: 420px;
    }

    .note__photo {
        width: 400px;
        height: 500px;
    }
}

@media screen and (max-width: 1141px) {
    .note__container {
        justify-content: space-between;
    }

    .note__info {
        margin-right: 0;
    }

    .note__title {
        font-size: 35px;
    }

    .note__qb {
        margin-top: 40px;
        width: 360px;
        padding: 10px;
    }

    .note__photo {
        width: 350px;
        height: 420px;
    }

    .note__quote {
        font-size: 18px;
    }
}

@media screen and (max-width: 1026px) {
    .note {
        padding: 30px 40px;
    }

    .note__info {
        max-width: 420px;
    }
}

@media screen and (max-width: 880px) {
    .note {
        margin-bottom: 70px;
        padding: 0 40px;
    }

    .note__container {
        flex-direction: column-reverse;
        align-items: center;
    }

    .note__info {
        margin-top: 20px;
        max-width: 500px;
    }

    .note__box {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .note__title {
        font-size: 35px;
        text-align: center;
    }

    .note__text {
        margin: 10px 0 0 0;
        font-size: 16px;
    }

    .note__photo {
        width: 450px;
        height: 500px;
    }

    .note__quote {
        font-size: 18px;
    }

    .note__qb {
        margin: 30px 0 0 0;
        padding: 20px;
    }

    .note__btn {
        margin-top: 30px;
    }
}

@media screen and (max-width: 540px) {
    .note {
        padding: 0 20px;
    }

    .note__info {
        max-width: 100%;
    }

    .note__box {
        width: 100%;
    }

    .note__title {
        font-size: 30px;
    }

    .note__text {
        font-size: 14px;
        text-align: center;
    }

    .note__qb {
        max-width: 100%;
        padding: 10px;
    }

    .note__photo {
        width: 100%;
        height: 400px;
    }

    .note__quote {
        font-size: 16px;
    }
    
    .note__link {
        font-size: 16px;
    }

}