.contacts {
    height: 50vh;
}

.contacts__container {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contacts__title {
    margin: 0 0 50px 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
}

.contacts__subtitle {
    margin: 0 0 15px 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
}

.contacts__number {
    margin: 0 0 10px 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
}

.contacts__link-tel {
    text-decoration: none;
    color:#696969;
    opacity: 1;
    transition: opacity .3 ease;
}

.contacts__link-tel:hover {
    opacity: .6;
}

.contacts__social-title {
    margin: 20px 0 15px 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
}

.contacts__social-list {
    list-style: none;
    display: flex;
    gap: 15px;
    padding: 0;
    margin: 0;
}

.contacts__social-icon {
    width: 30px;
    height: 30px;
    object-fit: cover;
    opacity: .6;
    transition: opacity .3 ease;
}

.contacts__social-icon:hover {
    cursor: pointer;
    opacity: 1;
}

@media screen and (max-width:880px) {
    .contacts {
        height: 35vh;
    }

    .contacts__container {
       margin-top: 120px;
    }
}

@media screen and (max-width:695px) {
    .contacts {
        height: 30vh;
    }
}