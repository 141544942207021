.services__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 60px;
    padding: 0 70px;
}

.services__section {
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.services__image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-image: url('../../images/about.jpg');
    background-position: center;
    background-size: cover;
}

.services__qb {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    background: linear-gradient(to right, #D3D8DB 0, #D3D8DB 50%, transparent 50%, transparent 100%), linear-gradient(to right, transparent 0, transparent 50%, #D3D8DB 50%, #D3D8DB 100%), linear-gradient(to bottom, #D3D8DB 0, #D3D8DB 100%), linear-gradient(to bottom, #D3D8DB 0, #D3D8DB 100%);
    background-size: 50% 2px, 50% 2px, 2px 50%, 2px 50%;
    background-position: 0 0, 100% 100%, 0 0, 100% 100%;
    background-repeat: no-repeat;
}

.services__quote {
    margin: 0 0 16px 0;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.2;
}

.services__quote-name {
    margin-bottom: 30px;
    font-size: 18px; 
 }

.services__list {
    list-style: none;
    padding: 0;
}

.services__box {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.services__title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 35px;
}

.servisec__wrapper {
    max-width: 900px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.sevices__pharagrph {
    width: 100%;
}

.services__img {
    width: 100%;
    height: 300px;
    object-fit: cover;
}

.services__description {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.4;
}

@media screen and (max-width: 1026px) {
    .services__container {
        padding: 0 40px;
    }
}

@media screen and (max-width: 880px) {
    .services__description {
        font-size: 18px;
    }
}

@media screen and (max-width: 540px) {
    .services__container {
        padding: 0 20px;
    }

    .services__title {
        font-size: 30px;
    }

    .services__description {
        font-size: 16px;
    }
}

@media screen and (max-width: 390px) {
    .services__quote {
        font-size: 14px;
    }

    .services__quote-name {
        font-size: 16px;
    }
}

@media screen and (max-width: 350px) {
    .services__title {
        font-size: 26px;
    }
}