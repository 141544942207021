.header {
    max-width: 100%;
    height: 60px;
    margin: 0;
    padding: 20px 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header__logo {
    width: 120px;
    height: 60px;
    background-image: url('../../images/logo.PNG');
    background-repeat: no-repeat;
    background-position: left;
    background-size: contain;
    cursor: pointer;
}

.header__elements {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header__links {
    padding: 0;
    list-style: none;
    display: flex;
}

.header__link {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.3;
    margin: 0 25px 0 0;
}

.header__link-to {
    color: #000;
    transition: color .3 ease;
    text-decoration: none;
}

.header__link-to:hover {
    color: #fff;
    cursor: pointer;
}

.header__link-to_gray:hover {
    color: #a2a4a5;
}

.header__contact {
    margin-left: 100px;
    display: flex;
    align-items: center;
}

.header__number {
    color: #696969;
    text-decoration: none;
}

.header__number:hover {
    text-decoration: underline;
}

.header__icon {
    margin: 0 5px 0 0;
    width: 30px;
    height: 30px;
    object-fit: cover;
}

.header__burger-btn {
    display: none;
    padding: 0;
    margin: 0;
    border: none;
    width: 32px;
    height: 35px;
    background-color: inherit;
    cursor: pointer;
}

.header__bar {
    width: 100%;
    height: 2px;
    background-color: #000;
    margin: 3px 0;
    transition: .2s;
}

.header__bar_close.header__bar1 {
    transform: translate(0, 11px)
    rotate(-45deg);
}

.header__bar_close.header__bar2 {
    opacity: 0;
}

.header__bar_close.header__bar3 {
    transform: translate(0, -5px)
    rotate(45deg);
}

@media screen and (max-width: 1280px) {
    .header__contact {
        margin-left: 70px;
    }
}

@media screen and (max-width: 1026px) {
    .header {
        padding: 20px 40px;
    }

    .header__link {
        margin-right: 20px;
    }

    .header__contact {
        margin-left: 30px;
    }
}

@media screen and (max-width: 880px) {
    .header {
        height: 50px;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        padding: 5px 40px;
        background-color: #D3D8DB;
        z-index: 5;
    }

    .header__logo {
        height: 50px;
    }

    .header__links {
       display: none;
    }

    .header__contact {
        display: none;
    }

    .header__burger-btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (max-width: 540px) {
    .header {
        padding: 5px 20px;
    }
}
