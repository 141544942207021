.education {
    margin: 0 auto;
    max-width: 1190px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.education__title {
    margin: 30px 0 30px 0;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
}

.slick-slider {
    width: calc(100% - 50px);
}

.slick-prev:before, .slick-next:before {
    color: #000 !important;
}

.education__diploma {
    height: 150px;
    margin: 0 auto;
    object-fit: cover;
}

.education__diploma:hover {
    cursor: url("../../images/zoom.svg"), pointer;
}

@media screen and (max-width: 1393px) {
    .education {
        max-width: 960px;
    }
}

@media screen and (max-width: 880px) {
    .education {
        max-width: 600px;
    }
}

@media screen and (max-width: 680px) {
    .education {
        max-width: 400px;
    }
    
    .slick-dots li {
        width: 12px !important;
        height: 12px !important;
    }
}

@media screen and (max-width: 453px) {
    .education {
        max-width: 350px;
    }

    .education__title {
        font-size: 22px;
    }
}