.about__about {
    margin: 60px 0 0 0;
    padding: 0 70px;
}

.about__title {
    margin: 0 0 50px 0;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 60px;
    line-height: 1.2;
    text-align: center;
    color: #a2a4a5
}

.about__container {
    max-width: 100%;
    display: flex;
    justify-content: center;
}

.about__img {
    width: 500px;
    height: 570px;
    object-fit: cover;
}

.about__box {
    margin: 0 150px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about__info {
    padding: 10px 20px;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    background: linear-gradient(to right, #D3D8DB 0, #D3D8DB 50%, transparent 50%, transparent 100%), linear-gradient(to right, transparent 0, transparent 50%, #D3D8DB 50%, #D3D8DB 100%), linear-gradient(to bottom, #D3D8DB 0, #D3D8DB 100%), linear-gradient(to bottom, #D3D8DB 0, #D3D8DB 100%);
    background-size: 50% 2px, 50% 2px, 2px 50%, 2px 50%;
    background-position: 0 0, 100% 100%, 0 0, 100% 100%;
    background-repeat: no-repeat;
}

.about-subtitle {
    margin: 0 0 40px 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 1.3;
}

.about__text {
    margin: 10px 0;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 1.2;
}

.about__link {
    margin-top: 50px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #a2a4a5;
    opacity: 1;
    transition: opacity 0.3 ease;
}

.about__link:hover {
    cursor: pointer;
    opacity: 0.6;
}

.about__education {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 70px;
}

.about__education-subtitle {
    width: 220px;
    padding: 5px 30px;
    border-bottom: 2px solid #D3D8DB;
    text-align: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    letter-spacing: -0.04em;
}

.about__education-box {
    max-width: 1190px;
    padding: 0 0 0 20px;
    border-left: 2px solid #D3D8DB;
}

.about__education-text {
    margin: 10px 0;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.3;
}

@media screen and (max-width: 1393px) {
    .about__box {
        margin-right: 100px;
        justify-content: space-between;
    }

    .about__info {
        width: 420px;
    }

    .about__img {
        width: 400px;
        height: 500px;
    }

    .about__text {
        margin: 5px 0;
        font-size: 16px;
    }

    .about__link {
        margin: 0;
    }

    .about__education-box {
        max-width: 960px;
    }

    .about__education-text {
        font-size: 17px;
    }
}

@media screen and (max-width: 1141px) {
    .about__container {
        justify-content: space-between;
    }

    .about__box {
        margin-right: 0;
    }

    .about__info {
        padding: 10px;
    }

    .about__img {
        width: 350px;
        height: 450px;
    }

    .about__link {
        font-size: 18px;
    }
}

@media screen and (max-width: 1026px) {
    .about__about {
        margin-top: 50px;
        padding: 30px 40px;
    }

    .about__title {
        font-size: 50px;
    }

    .about__info {
        padding: 10px 10px 5px 10px;
        width: 370px;
    }

    .about__text {
        font-size: 15px;
    }

    .about__education-text {
        font-size: 16px;
    }
}

@media screen and (max-width: 880px) {
    .about__about {
        margin-top: 60px;
        padding: 60px 40px;
    }

    .about__text {
        font-size: 45px;
    }

    .about__container {
        flex-direction: column-reverse;
        align-items: center;
    }

    .about__box {
        margin-top: 30px;
        max-width: 500px;
    }

    .about__info {
        width: calc(100% - 40px);
        padding: 15px 20px;
    }

    .about__text {
        margin: 10px 0 0 0;
        font-size: 16px;
        line-height: 1.2;
    }

    .about__link {
        margin-top: 30px;
        font-size: 20px;
    }

    .about__img {
        width: 450px;
        height: 500px;
    }

    .about__education {
        margin-top: 50px;
    }
}

@media screen and (max-width: 540px) {
    .about__about {
        padding: 60px 20px;
    }

    .about__info {
        max-width: 100%;
    }

    .about__box {
        max-width: 100%;
    }

    .about__text {
        font-size: 14px;
        text-align: center;
    }

    .about__img {
        width: 100%;
        height: 400px;
    }

    .about__text {
        font-size: 15px;
    }

    .about__link {
        font-size: 18px;
    }
    
    .about__education-subtitle {
        font-size: 30px;
    }
}

@media screen and (max-width: 373px) { 
    .about__title {
        font-size: 40px;
    }
}

@media screen and (max-width: 351px) { 
    .about__link {
        font-size: 16px;
    }
}

