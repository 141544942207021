.video {
    margin: 70px auto;
    padding-bottom: 20px;
    max-width: 1190px;
    display: flex;
    flex-direction: column;
    background: linear-gradient(to right, #D3D8DB 0, #D3D8DB 50%, transparent 50%, transparent 100%), linear-gradient(to right, transparent 0, transparent 50%, #D3D8DB 50%, #D3D8DB 100%), linear-gradient(to bottom, #D3D8DB 0, #D3D8DB 100%), linear-gradient(to bottom, #D3D8DB 0, #D3D8DB 100%);
    background-size: 50% 2px, 50% 2px, 2px 50%, 2px 50%;
    background-position: 0 0, 100% 100%, 0 0, 100% 100%;
    background-repeat: no-repeat;
}

.video__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.video__title {
    width: 220px;
    padding: 5px 30px;
    border-bottom: 2px solid #D3D8DB;
    text-align: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    letter-spacing: -0.04em;
}

.video__mainVideo {
    margin-bottom: 30px;
    width: 450px;
    height: 250px;
}

.video__boxes {
    width: 880px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.slick-slider {
    width: calc(100% - 50px);
}

.slick-prev:before, .slick-next:before {
    color: #000 !important;
}

.video__video {
    height: 150px;
    margin: 0 auto;
}

.video__text {
    margin: 15px 0 50px 0;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
}

.video__link {
    display: flex;
    align-items: center;
    margin-left: calc((100% - 840px)/2);
}

.video__link-element {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #a2a4a5;
    opacity: 1;
    transition: opacity 0.3 ease;
    text-decoration: none;
}

.video__link-element:hover {
    cursor: pointer;
    opacity: .6;
}

.video__link-icon {
    margin-left: 10px;
    width: 20px;
    height: 20px;
    object-fit: cover;
    align-self: center;
}

@media screen and (max-width: 1141px) {  
    .video__boxes {
        width: 700px;
    }

    .video__box {
        margin: 0 30px;
    }

    .video__text {
        margin: 10px 0 35px;
    }

    .video__link {
        margin-top: 30px;
        margin-left: calc((100% - 680px)/2);
    }
}

@media screen and (max-width: 880px) {  
    .video__mainVideo {
        width: 350px;
    }

    .video__title {
        font-size: 28px;
        margin-bottom: 20px;
    }

    .video__boxes {
        width: 600px;
    }

    .video__box {
        margin: 0 30px;
    }

    .video__video {
        height: 120px;
    }

    .video__text {
        font-size: 15px;
    }

    .video__link {
        margin-left: calc((100% - 590px)/2);
    }
}

@media screen and (max-width: 751px) {
    .video__boxes {
        width: 400px;
    }

    .video__box {
        margin: 0 65px;
        height: 150px;
    }

    .video__link {
        margin-left: calc((100% - 390px)/2);
        margin-top: 50px;
    }

    .video__link-element {
        font-size: 18px;
    }

    .video__link-icon {
        width: 18px;
        height: 18px;
    }
}

@media screen and (max-width: 540px) {
    .video__mainVideo {
        width: 300px;
    }

    .video__title {
        margin-bottom: 0;
    }

    .video__boxes {
        width: 300px;
    }

    .video__box {
        margin: 0 20px;
        height: 150px;
    }

    .video__link {
        margin-left: calc((100% - 290px)/2);
    }

}

@media screen and (max-width: 393px) {
    .video__mainVideo {
        width: 250px;
    }

    .video__boxes {
        width: 250px;
    }

    .video__box {
        margin: 0 2px;
    }

    .video__link {
        margin-left: calc((100% - 240px)/2);
    }

    .video__link-element {
        font-size: 16px;
    }
}