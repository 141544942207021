.login {
    padding: 0 20px;
    display: flex;
    align-items: center;
}


.login__auth {
    margin: 200px auto;
    width: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.login__title {
    margin: 0 0 30px 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    text-align: center;
}

.login__form {
    width: 100%;
    margin: 20px 0 0 0;
    padding: 20px;
}

.login__form-set {
    width: 100%;
    border: none;
    margin: 0;
    padding: 0;
}

.login__input {
    width: calc(100% - 40px);
    height: 35px;
    margin: 0 0 20px;
    padding: 2px 20px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    border: none;
    background-color: #f0f0f0;
}

.login__input::placeholder {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.2;
}

.login__remember {
    margin: 0 0 20px 0;
    display: flex;
    align-items: center;
}

.login__ceckbox {
    border: 1px solid #DCDCDC;
}

.login__label {
    margin: 0 0 0 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
}

.login__button {
    margin: 20px 0 0 0;
    padding: 0;
    width: 100%;
    height: 39px;
    background-color: #DCDCDC;
    border: none;
    border-radius: 3px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    opacity: 1;
    transition: opacity .3 ease;
    cursor: pointer;
}

.login__button:hover {
    opacity: 0.6;
}

.login__link {
    margin: 50px 0 0 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #777777;
    opacity: 1;
    transition: opacity .3 ease;
    cursor: pointer;
}

.login__link:hover {
    opacity: 0.6;
}