.section__item {
    width: 100%;
    list-style: none;
}

.section__container {
    margin-bottom: 60px;
}

.section__box {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.section__btn-delete {
    margin: 0;
    padding: 0;
    border: none;
    cursor: pointer;
    width: 20px;
    height: 20px;
    background-image: url(../../images/deleteIcon.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-color: inherit;
    opacity: 1;
    transition: 0.3 opacity ease;
}

.section__btn-delete:hover {
    opacity: .6;
    cursor: pointer;
}

.section__title {
    margin: 0 auto 30px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    text-align: center;
}

.section__list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 250px));
    grid-template-rows: auto;
    grid-column-gap: 40px;
    grid-row-gap: 20px;
    margin: 0;
    padding: 0;
    list-style: none;
    justify-content: center;
}

.section__block {
    position: relative;
}

.section__img {
    width: 100%;
    height: 250px;
    margin: 0;
    object-fit: cover;
}

.section__img:hover {
    cursor: url("../../images/zoom.svg"), pointer;
}

.section__btnDelImg {
    width: 15px;
    height: 15px;
    border: none;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1;
    background-image: url('../../images/deleteIcon.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-color: inherit;
    opacity: 1;
    transition: 0.3 opacity ease;
}

.section__btnDelImg:hover {
   opacity: .6;
   cursor: pointer;
}

.section__btn {
    display: flex;
    margin: 20px auto;
}

.section__btn-else {
    margin: 20px auto 0;
    width: 250px;
    height: 50px;
    border: 1px solid #000;
    border-radius: 4px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    background-color: #fff;
    color: #000;
    transition: background-color 0.3 ease;
}

.section__btn-else:hover {
    background-color: #D3D8DB;
    cursor: pointer;
    color: #fff;
    border: none;
}

.section__addImage {
    margin-top: 30px;
    display: flex;
}

.section__add {
    margin: 0 auto;
}

.section__inputfile {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    opacity: 1;
    transition: opacity 0.3 ease;
}

.section__inputfile:hover {
    opacity: .6;
    cursor: pointer;
}


.section__download {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    opacity: 1;
    transition: .3 opacity ease;
}

.section__download:hover {
    opacity: .6;
    cursor: pointer;
}

@media screen and (max-width: 890px) {
    .section__title {
        font-size: 24px;
    }
}

@media screen and (max-width: 540px) {
    .section__title {
        font-size: 22px;
    }
}