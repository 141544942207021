.footer {
    margin-top: 100px;
    padding: 30px 70px;
    height: 260px;
    max-width: 100%;
    background-color: #D3D8DB;
}

.footer__container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgba(105, 105, 105, .3);
}

.footer__box {
    width: 900px;
    display: flex;
    justify-content: space-between;
}

.footer__logo {
    margin-right: 70px;
    width: 170px;
    height: 90px;
    background-image: url('../../images/logo.PNG');
    background-repeat: no-repeat;
    background-position: left;
    background-size: contain;
    cursor: pointer;
}

.footer__items {
    margin-top: 30px;
    height: 206px;
    display: flex;
    flex-direction: column;
}

.footer__subtitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.2;
    color: #696969;
    margin: 0;
}

.footer__list {
    margin: 20px 0;
    list-style: none;
    padding: 0;
}

.footer__links {
    margin: 0 0 10px 0;
    padding: 0;
}

.footer__link {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.2;
    color: #000000;
    text-decoration: none;
    transition: color .3 ease;
}

.footer__link:hover {
    color: #fff;
    cursor: pointer;
}

.footer__contacts {
    margin: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.2;
    color: #000000;
    text-decoration: none;
    transition: color .3 ease;
}

.footer__contacts:hover {
    color: #fff;
    cursor: pointer;
}

.footer__number {
    margin: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.2;
    color: #000000;
    text-decoration: none;
    transition: color .3 ease;
}

.footer__link-tel {
    color: #000000;
    text-decoration: none;
}

.footer__link-tel:hover {
    color: #fff;
    cursor: pointer;
}

.footer__contacts-info {
    margin: 5px 0 0 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.2;
    color: #000000;
}

.footer__info {
    margin: 20px 0;
}

.footer__contact {
    display: flex;
    align-items: center;
}

.footer__copyright {
    margin: 20px 0;
    text-align: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #696969
}

@media screen and (max-width: 1141px) {
    .footer__logo {
        width: 120px;
        height: 90px;
    }
}

@media screen and (max-width: 1026px) {
    .footer {
        padding: 30px 40px;
    }

    .footer__logo {
        width: 90px;
        height: 60px;
        margin-right: 50px;
    }

    .footer__link {
        font-size: 15px;
    }

    .footer__number {
        font-size: 15px;
    }
    
    .footer__contacts {
        font-size: 15px;
    }

    .footer__contacts-info {
        font-size: 14px;
    }
}

@media screen and (max-width: 880px) {
    .footer {
        height: 310px;
        margin-top: 70px;
    }

    .footer__container {
        flex-direction: column;
    }

    .footer__logo {
        margin-right: 0;
    }

    .footer__box {
        width: 100%;
    }

    .footer__copyright {
        margin: 14px 0;
    }
}

@media screen and (max-width: 695px) {
   .footer {
    height: 430px;
   }

    .footer__box {
        width: 350px;
        flex-wrap: wrap;
    }

    .footer__element {
        height: 120px;
        margin-top: 0;
    }
}

@media screen and (max-width: 540px) {
    .footer {
     padding: 20px;
    }
 
     .footer__box {
         width: 280px;
     }
 }