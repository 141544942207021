.imagePopup {
    display: flex; 
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.9);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s linear 0s;
    min-width: 0;
}

.imagePopup_opened {
    visibility: visible;
    opacity: 1;
}

.imagePopup__wrapper {
    width: 100%;
    height: 100%;
    display: flex; 
    justify-content: center;
    align-items: center;
    background-color: transparent;
    position: relative;
}

.imagePopup__close {
    width: 20px;
    height: 20px;
    border: none;
    background-image: url(../../images/closeIcon.png);
    background-size: contain;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    opacity: 1;
    position: absolute;
    top: 10px;
    right: 10px;
}

.imagePopup__container {
    width: calc(85vh + 100px);
    min-width: 0;
    margin: 0;
    padding: 0;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imagePopup__box {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 0;
    margin-bottom: 20px;
}

.imagePopup__image {
    max-width: 85vh;
    max-height: 85vh;
    object-fit: cover;
    position: relative;
}

.imagePopup__close:hover {
    opacity: 0.6;
}

.swiper-button-prev {
    color: #fff !important;
}

.swiper-button-next {
    color: #fff !important;
}

.swiper-pagination-bullet {
    background-color: #fff !important;
}

@media screen and (max-width: 1021px){ 
    .imagePopup__container {
       width: calc(60vh + 100px);
    }

    .imagePopup__image {
        max-width: 60vh;
    }
}

@media screen and (max-width: 890px) {
    .imagePopup__close {
        top: 70px
    }
}

@media screen and (max-width: 642px){ 
    .imagePopup__container {
       width: 100%;
    }

    .imagePopup__image {
        width: calc(100% - 100px)
    }
}